<template>
  <div :class="layout ? 'shader' : 'shader2'" style="background-color:rgba(0, 0, 0, 0.62);text-align:center">
    <div class="posicao">
      <div>
        <b-spinner v-if="black" label="Spinning" style="width: 5rem; height: 5rem;z-index: 999999;"></b-spinner>
        <b-spinner v-else label="Spinning" variant="success" style="width: 5rem; height: 5rem;z-index: 999999;"></b-spinner>
        <br />
      </div>
      <div class="success-save" v-if="criandoSeguro">
        Aguarde...
      </div>
      <div class="success-save" v-else>
        <p>Estamos salvando seus dados</p>
        Aguarde...
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["criandoSeguro", "black"],
  data() {
    return {
      layout: false
    }
  },
  created() {
    this.layout = window.innerWidth > 768;
  },
}
</script>


<style scoped>
.posicao {
  top: 244px !important;
  bottom: 0 !important;
  right: 0px !important;
  left: 136px !important;
  padding-top: 25px !important;
  position: sticky !important;
  z-index: 999999;
}

.success-save {
  color: white;
  font-size: 15px;
  margin-top: 10px;
  font-weight: 600;
}

.shader {
  width: 100%;
  background-color: #28a745;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 999999;
  cursor: not-allowed
}

.shader2 {
  height: 389%;
  background-color: #28a745;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 999999;
  cursor: not-allowed
}
</style>